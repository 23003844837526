
import React from "react"
import logo from "../../static/logo.png"
class BlogLogo extends React.Component {
  render() {
    return (
       <a href="http://dinaelhanan.com"> <p class="logo"  >digital<br/>dina</p></a>
     
    )
  }
}

export default BlogLogo
