
import React from "react"
import logo from "../../static/logo.png"
class Logo extends React.Component {
  render() {
    return (
       <a href="http://dinaelhanan.com"> <p class="logo"  >digitaldina</p></a>
     
    )
  }
}

export default Logo
